import Cookies from 'js-cookie';

export const setSecureCookie = (name: string, value: string) => {
  Cookies.set(name, value, {
    secure: true,
    sameSite: 'Strict',
    path: '/',
  });
};

export const getCookie = (name: string) => {
  return Cookies.get(name);
};

export const removeCookie = (name: string) => {
  Cookies.remove(name, { path: '/' });
};
