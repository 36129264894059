import router from 'next/router';
import {
  useCallback,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { getShopSearchUrlParams } from './useShopSearch';
import { getPageInfo } from '@/config/pages';
import {
  defaultShopSearchParams,
  ShopSearchParams
} from '@/types/shopSearch';
import {
  setSecureCookie,
  removeCookie,
} from '@/utils/cookie';
import { buildUrlParamsString } from '@/utils/url';

export type UseGetLocationProps = {};

export const useGetLocation = () => {

  // 位置情報の読み込み状態
  const [isCurrentLocationLoading, setIsCurrentLocationLoading] = useState<boolean>(false);

  // 位置情報の取得とエラーの場合の表示
  const getCurrentPosition = useCallback(() => {
    return new Promise<GeolocationPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, (error) => {
        if (error instanceof GeolocationPositionError) {
          switch (error.code) {
            case GeolocationPositionError.PERMISSION_DENIED:
              return reject(new Error('位置情報の取得することができませんでした。ブラウザの設定を変更するか、エリア検索をご利用ください。'));
            case GeolocationPositionError.POSITION_UNAVAILABLE:
              return reject(new Error('位置情報の取得が出来ませんでした。エリア検索をご利用ください。'));
            case GeolocationPositionError.TIMEOUT:
              return reject(new Error('タイムアウトが発生しました。もう一度試してください。または、エリア検索をご利用ください。'));
            default:
              return reject(new Error('位置情報の取得中にエラーが発生しました。ブラウザの設定を変更するか、エリア検索をご利用ください。'));
          }
        } else {
          reject(new Error('位置情報の取得中にエラーが発生しました。エリア検索をご利用ください。'));
        }
      });
    });
  }, []);

  const setLocationCookies = useCallback((latitude: number, longitude: number) => {
    const location = JSON.stringify({ currentLat: latitude, currentLng: longitude });
    setSecureCookie('currentLocation', location);
  },[]);

  const removeLocationCookies = useCallback(() => {
    removeCookie('currentLocation');
  },[]);

  // 現在地検索のみの実行ボタンのクリックハンドラ
  const getLocationOnlyShopSearchHandler = useCallback(() => {
    const searchShopParams: ShopSearchParams = { ...defaultShopSearchParams };
    return async () => {
      // 現在地情報の取得に成功した場合、結果をCookieに保存し
      // isLocationSearchをtrueにしたクエリパラメータを付与してsearchに飛ばす
      setIsCurrentLocationLoading(true);
      try {
        const position: GeolocationPosition = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        setLocationCookies(latitude, longitude);
        searchShopParams.isLocationSearch = true;
      } catch (error) {
        setIsCurrentLocationLoading(false);
        if (error instanceof Error) {
          toast.error(error.message, {
            position: 'top-center',
            duration: 5000,
          });
        }
        return;
      }
      await router.push(`${getPageInfo('studio/search', {}).href}${buildUrlParamsString(getShopSearchUrlParams(searchShopParams))}`);
    };
  }, [getCurrentPosition, setLocationCookies]);

  return {
    isCurrentLocationLoading,
    getLocationOnlyShopSearchHandler,
    getCurrentPosition,
    setLocationCookies,
    removeLocationCookies,
  };
};
