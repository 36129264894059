import { z } from 'zod';

export const shopSearchParamsSchema = z.object({
  prefectureId:  z.number().or(z.null()),
  cityIds: z.array(z.number()),
  railroadLineIds: z.array(z.number()),
  railroadStationIds: z.array(z.number()),
  courseGenreIds: z.array(z.number()),
  particularConditionIds: z.array(z.number()),
  q: z.string(),
  isLocationSearch: z.boolean()
});

export type ShopSearchParams = z.infer<typeof shopSearchParamsSchema>;

export const defaultShopSearchParams: ShopSearchParams = {
  prefectureId: null,
  cityIds: [],
  railroadLineIds: [],
  railroadStationIds: [],
  particularConditionIds: [],
  courseGenreIds: [],
  q: '',
  isLocationSearch: false,
};
