import {
  Box,
  Spinner,
  Text
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { SubmitButton } from '@/components';

export type SearchButtonByLocationProps = {
  getLocation:() => void;
  isLocationLoading: boolean;
  icon?: ReactNode;
  margin?: { base: number; lg?: number; };
};

export const SearchButtonByLocation = ({
  getLocation,
  isLocationLoading,
  icon,
  margin = { base: 8, lg: 0 }
}: SearchButtonByLocationProps)  => {
  return(
    <Box
      textAlign={{ base: 'center', lg: 'left' }}
    >
      <SubmitButton
        mt={margin}
        mb={{ base: 0, lg: 4 }}
        w={{ base:'93%' ,lg: '40%' }}
        onClick={getLocation}
        isDisabled={isLocationLoading ? true : false}
      >
      {isLocationLoading ? (
        <>
          <Spinner
            color={'brand.300'}
            mr={2}
            size={'md'}
            speed={'0.7s'}
          />
          <Text
            textStyle={'h4'}
            pt={'1px'}
            color={'brand.300'}
          >
            取得中...
          </Text>
        </>
      ) : (
        <>
          {icon && icon}
          <Text
            textStyle={'h4'}
            fontWeight={'bold'}
          >
            現在地から探す
          </Text>
        </>
      )}
      </SubmitButton>
    </Box>
  );
};
