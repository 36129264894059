import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useForm,
  useWatch,
} from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useGetLocation } from './useGetLocation';
import { getPageInfo } from '@/config/pages';
import {
  ShopSearchConditions
} from '@/features/shops/types/shopSearch';
import {
  ShopSearchConditionsQuery,
  ShopSearchConditionsQueryVariables,
  useShopSearchConditionsQuery
} from '@/graphql/generated/graphql';
import {
  defaultShopSearchParams as originalShopSearchParams,
  ShopSearchParams,
  shopSearchParamsSchema
} from '@/types/shopSearch';
import { getCookie } from '@/utils/cookie';
import { buildUrlParamsString } from '@/utils/url';

type UseShopSearchProps = {
  defaultShopSearchParams?: ShopSearchParams;
  defaultShopSearchConditions: ShopSearchConditions;
}

export type ShopSearchedConditions = {
  q: string;
  isLocationSearch: boolean;
  prefecture: ShopSearchConditions['allPrefectures'][number] | null;
  cities: ShopSearchConditions['cities']['items'];
  railroadLines: ShopSearchConditions['railroadLines'][number][];
  railroadStations: ShopSearchConditions['railroadLines'][number]['railroadStations']['items'];
  courseGenres: ShopSearchConditions['courseGenres'];
  particularConditions: ShopSearchConditions['particularConditions'];
};

export const getShopSearchUrlParams = (params: ShopSearchParams) => {
  return {
    q: JSON.stringify(params),
  };
};

const getShopSearchedCondition = (params: ShopSearchParams, shopSearchConditions: ShopSearchConditions): ShopSearchedConditions => {
  const {
    allPrefectures,
    cities,
    railroadLines,
    courseGenres,
    particularConditions
  } = shopSearchConditions;
  return {
    q: params.q,
    isLocationSearch: params.isLocationSearch,
    prefecture: allPrefectures.find(prefecture => prefecture.id === params.prefectureId) ?? null,
    cities: cities.items
      .filter(city => params.cityIds.includes(city.id))
      .sort(v => v.id),
    railroadLines: railroadLines
      .filter(railroadLine => params.railroadLineIds.includes(railroadLine.id))
      .sort((a, b) => {
        if (a.railroadCompanyId < b.railroadCompanyId) return -1;
        if (a.railroadCompanyId > b.railroadCompanyId) return 1;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
      }),
    railroadStations: railroadLines
      .sort((a, b) => {
        if (a.railroadCompanyId < b.railroadCompanyId) return -1;
        if (a.railroadCompanyId > b.railroadCompanyId) return 1;
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;

        return 0;
      })
      .flatMap(({ railroadStations: { items } }) =>
        items.filter(railroadStation =>
          params.railroadStationIds.includes(railroadStation.id))
      )
      .filter((railroadStation, index, self) =>
        index === self.findIndex(o => o.id === railroadStation.id)
      )
      .sort((a, b) => Number(a.sortNo) - Number(b.sortNo)),
    courseGenres: courseGenres
      .filter(v => params.courseGenreIds.includes(v.id))
      .sort(v => v.id),
    particularConditions: particularConditions
      .filter(v => params.particularConditionIds.includes(v.id))
      .sort(v => v.id),
  };
};

export const useShopSearch = ({
  defaultShopSearchParams,
  defaultShopSearchConditions,
}: UseShopSearchProps) => {

  const { getCurrentPosition, setLocationCookies, removeLocationCookies } = useGetLocation();
  const router = useRouter();

  // 店舗を検索中かどうか
  const [isSearching, setIsSearching] = useState<boolean>(false);

  // 検索条件を保持する
  const [shopSearchConditions, setShopSearchConditions] = useState<ShopSearchConditions>(defaultShopSearchConditions);

  // 検索条件を取得中かどうか
  const [isShopSearchConditionsLoading, setIsShopSearchConditionsLoading] = useState<boolean>(false);

  const shopSearchForm = useForm<ShopSearchParams>({
    resolver: zodResolver(shopSearchParamsSchema),
    mode: 'onChange',
    defaultValues: defaultShopSearchParams ?? originalShopSearchParams,
  });

  const { handleSubmit, getValues, reset, control, setValue } = shopSearchForm;

  const watchingPrefectureId = useWatch({
    name: 'prefectureId',
    control,
  });

  const watchingRailroadStationIds = useWatch({
    name: 'railroadStationIds',
    control,
  });

  // 現在のprefectureIdを保持する
  const [currentPrefectureId, setCurrentPrefectureId] = useState<ShopSearchParams['prefectureId'] | null>(watchingPrefectureId);

  const [railroadLineAccordionIndexes, setRailroadLineAccordionIndexes] = useState<number[]>([]);

  // 位置情報のトグルの状態
  const [isLocationSwitchOn, setIsLocationSwitchOn] = useState<ShopSearchParams['isLocationSearch']>(getValues('isLocationSearch'));

  // 位置情報の読み込み状態
  const [isCurrentLocationLoading, setIsCurrentLocationLoading] = useState<boolean>(false);


  // 位置情報をCookieにセット
  const setCurrentPosition = useCallback(async () => {
    setIsCurrentLocationLoading(true);
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      setLocationCookies(latitude, longitude);
      setIsLocationSwitchOn(true);
      setValue('isLocationSearch', true);
      setIsCurrentLocationLoading(false);
    } catch (error) {
      setIsCurrentLocationLoading(false);
      setIsLocationSwitchOn(false);
      if (error instanceof Error) {
        toast.error(error.message, {
          position: 'top-center',
          duration: 5000,
        });
      }
    }
  }, [getCurrentPosition, setLocationCookies, setValue]);

  const UPDATE_INTERVAL = 1000 * 60 * 10;
  const intervalId = useRef<number | undefined>(undefined);

  //定期的に位置情報を取得
  useEffect(() => {
    window.clearInterval(intervalId.current);
    const isLocationSearch = getValues('isLocationSearch');
    if (isLocationSwitchOn && isLocationSearch) {
      setCurrentPosition();
      intervalId.current = window.setInterval(setCurrentPosition, UPDATE_INTERVAL);
    }
    return () => {
      window.clearInterval(intervalId.current);
      removeLocationCookies();
    };
  }, [isLocationSwitchOn, getValues, setCurrentPosition, removeLocationCookies, UPDATE_INTERVAL]);

  // 位置情報をオフ/オン 緯度経度リセット/市区町村・沿線リセット
  const handleCurrentLocationToggle = useCallback(() => {
    if (isLocationSwitchOn) {
      removeLocationCookies();
      setIsLocationSwitchOn(false);
      setValue('isLocationSearch', false);
      setValue('prefectureId', 13);
    } else {
      reset({
        ...originalShopSearchParams,
        q: getValues('q'),
        courseGenreIds: getValues('courseGenreIds'),
        particularConditionIds: getValues('particularConditionIds'),
      });
      setCurrentPosition();
    }
  }, [setCurrentPosition, getValues, isLocationSwitchOn, reset, removeLocationCookies, setValue]);

  const queryClient = useQueryClient();

  const queryVariables: ShopSearchConditionsQueryVariables = useMemo(() => {
    return {
      prefectureId: watchingPrefectureId,
      railroadLineIds: !watchingPrefectureId ? getValues('railroadLineIds') : undefined,
      skipLoadDataCities: !watchingPrefectureId,
      skipLoadDataRailroadLines: (getValues('railroadLineIds') ?? []) && !watchingPrefectureId,
    };
  }, [getValues, watchingPrefectureId]);

  const {
    refetch,
  } = useShopSearchConditionsQuery(queryVariables, {
    placeholderData: { shopSearchConditions: defaultShopSearchConditions },
    staleTime: 60 * 1000 * 1000,
    cacheTime: 60 * 1000 * 1000,
    enabled: false,
  });

  // 各店舗一覧ページにdynamicルーティングで遷移してきた場合、unmountされないのでpropsに変化が合った場合は更新する処理が必要となる
  // NOTE: https://zenn.dev/izumin/scraps/1adef895cf1b8c#comment-9544038b203f67
  useEffect(() => {
    setShopSearchConditions(defaultShopSearchConditions);

    if (defaultShopSearchParams) {
      reset(defaultShopSearchParams);
      setShopSearchedConditions(getShopSearchedCondition(defaultShopSearchParams, defaultShopSearchConditions));
    }
    // eslint-disable-next-line
  }, [defaultShopSearchParams, defaultShopSearchConditions]);

  // 都道府県に変更があった場合
  useEffect(() => {
    if (currentPrefectureId === watchingPrefectureId) return;

    setRailroadLineAccordionIndexes([]);
    setIsShopSearchConditionsLoading(true);

    setCurrentPrefectureId(watchingPrefectureId);
    // 検索条件をリセット
    reset({
      ...originalShopSearchParams,
      q: getValues('q'),
      courseGenreIds: getValues('courseGenreIds'),
      particularConditionIds: getValues('particularConditionIds'),
      prefectureId: watchingPrefectureId,
    });

    // 最新の情報を取得する
    (async () => {
      let queryData: ShopSearchConditionsQuery | undefined =
        queryClient.getQueryData(['shopSearchConditions', queryVariables]);

      if (!queryData) {
        const { data } = await refetch();
        if (!data) {
          setIsShopSearchConditionsLoading(false);
          return;
        }
        queryData = data;
      }

      setShopSearchConditions(queryData.shopSearchConditions);
      setIsShopSearchConditionsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchingPrefectureId]);

  // 路線にチェックが入ったら駅のチェックをコントロールする
  const handleChangeRailroadLineId = (railroadLineId: number, checked: boolean) => {
    const { railroadLines } = shopSearchConditions;
    const railroadLine = railroadLines.find(({ id }) => id === railroadLineId);
    if (!railroadLine) return;

    const targetRailroadStationIds = railroadLine.railroadStations.items.map(({ id }) => id).flat();
    const currentStationIds = getValues('railroadStationIds');
    setValue('railroadStationIds',
      checked
        ? Array.from(new Set([
          ...currentStationIds,
          ...targetRailroadStationIds,
        ]))
        : currentStationIds.filter(id => !targetRailroadStationIds.includes(id))
    );
  };

  useEffect(() => {
    const railroadLineIds = shopSearchConditions.railroadLines
      .filter((railroadLine) =>
        railroadLine.railroadStations.items
          .some(railroadStation => watchingRailroadStationIds.includes(railroadStation.id))
      ).map(({ id }) => id);

    // 自動開閉する場合
    setRailroadLineAccordionIndexes(_ => {
      return shopSearchConditions.railroadLines
        .map((railroadLine, index) => railroadLineIds.includes(railroadLine.id) ? index : -1)
        .filter(v => v !== -1);
    });

    setValue('railroadLineIds', railroadLineIds);
  }, [setValue, shopSearchConditions.railroadLines, watchingRailroadStationIds]);

  const toggleRailroadLineAccordionIndexes = useCallback((railroadLineId: number) => {
    const targetIndex = shopSearchConditions.railroadLines.findIndex(({ id }) => railroadLineId === id);
    setRailroadLineAccordionIndexes(prev => {
      if (prev.includes(targetIndex)) {
        return prev.filter(index => index !== targetIndex);
      }
      return [
        ...prev,
        targetIndex,
      ];
    });

  }, [shopSearchConditions.railroadLines]);

  // 検索した条件
  const [shopSearchedConditions, setShopSearchedConditions] = useState<ShopSearchedConditions>(getShopSearchedCondition(getValues(), shopSearchConditions));
  // 検索実行
  const getShopSearchHandler = useCallback(({ onSuccess }: { onSuccess?(): void } = {}) => {
    return handleSubmit(async (params) => {
      setIsSearching(true);
      const locationCookie = getCookie('currentLocation');
      if(isLocationSwitchOn && !locationCookie) {
        try {
          await setCurrentPosition();
        } catch (error) {
          console.error(error);
          setIsSearching(false);
          return;
        }
      }
      await router.push(`${getPageInfo('studio/search', {}).href}${buildUrlParamsString(getShopSearchUrlParams(params))}`);
      // 検索した条件を保持する
      if (shopSearchConditions) {
        setShopSearchedConditions(getShopSearchedCondition(params, shopSearchConditions));
      }
      onSuccess && onSuccess();
      setIsSearching(false);
    });
  }, [handleSubmit, router, shopSearchConditions, isLocationSwitchOn, setCurrentPosition]);

  return {
    isSearching,
    isShopSearchConditionsLoading,
    shopSearchForm,
    railroadLineAccordionIndexes,
    shopSearchConditions,
    getCurrentPosition,
    handleChangeRailroadLineId,
    handleCurrentLocationToggle,
    toggleRailroadLineAccordionIndexes,
    isLocationSwitchOn,
    isCurrentLocationLoading,
    shopSearchedConditions,
    getShopSearchHandler,
  };
};
